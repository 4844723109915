<template>
  <v-app>
    <v-app-bar app color="transparent" flat>
      <div class="d-flex align-center">
        <v-img
          alt="eCloud Logo"
          class="shrink mr-2"
          contain
          src="img/eCloud-logo.png"
          transition="scale-transition"
          height="40" />
      </div>
    </v-app-bar>

    <v-main class="px-5 grey lighten-5">
      <v-row justify="center">
        <v-col cols="12" md="7">
          <v-row justify="center">
            <card-service 
              v-for="servicio in servicios"
              :key="servicio.titulo"
              :titulo="servicio.titulo"
              :imagen="servicio.imagen"
              :url="servicio.url"
              :color="servicio.color">
            </card-service>
          </v-row>
        </v-col>
      </v-row>
    </v-main>
    <v-footer app color="blue-grey darken-4">
      <v-row justify="center">
        <v-col cols="12">
          <small class="white--text">
            ECLOUD SERVICIOS DE INNOVACION TECNOLOGICA SAPI DE CV  <br>
            ESI140920893 <br>
            Blvd. Rodriguez No 99 Local A, Hermosillo, Son. C.P. 83000 Col. Centro <br>
            Tel: (662) 4378519
          </small>
        </v-col>
        <v-col cols="auto">
          <b class="white--text">
            eCloud Servicios &copy; {{ new Date().getFullYear() }}
          </b>
        </v-col>
      </v-row>
    </v-footer>
  </v-app>
</template>

<script>
import CardService from './components/CardService';

export default {
  name: 'App',

  components: {
    CardService,
  },

  data: () => ({
    servicios: [
      {
        titulo: 'Helpdesk',
        imagen: 'img/helpdesk.png',
        url: 'https://helpdesk.ecloudstudio.com/#login',
        color: 'blue-grey',
      },
      {
        titulo: 'Welo Taxi',
        imagen: 'img/welotaxi.png',
        url: 'https://welo.mx/',
        color: 'teal lighten-5',
      },
      {
        titulo: 'Welobox',
        imagen: 'img/welobox-logo.png',
        url: 'https://app.welobox.com/',
        color: 'teal lighten-5',
      },
      {
        titulo: 'Gonload',
        imagen: 'img/gonload.png',
        url: 'https://gonload.com/',
        color: 'white',
      },
    ],
  }),
};
</script>
