<template>
  <v-col cols="12" md="3">
    <v-card
      class="rounded-xl elevation-8"
      @click="navegar"
      :color="color">
      <v-card-title>{{ titulo }}</v-card-title>
      <v-card-text>
        <v-img
          class="shrink"
          contain
          :src="imagen"
          transition="scale-transition"
          height="100"
        />
      </v-card-text>
    </v-card>
  </v-col>
</template>

<script>
  export default {
    name: 'cardService',
    props: {
      titulo: String,
      imagen: String,
      url: String,
      color: String,
    },
    data: () => ({
    }),
    methods: {
      navegar() {
        window.location.href = this.url;
      },
  },
  }
</script>
